import { LeftOutlined } from '@ant-design/icons'
import { Col, Row, Button } from 'antd'
import { ReferenceProposition as ReferencePropositionComponent } from 'ecedo-myportal-frontend-components'
import React, { FunctionComponent, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import LayoutBase from '../components/LayoutBase'
import LayoutCenter from '../components/LayoutCenter'
import useLanguage from '../hooks/useLanguage'
import { RootState } from '../store/reducers'
import { AuthState } from '../store/reducers/authReducer'
import translations from '../translation'
import { localizedRoutes } from '../utils'

const ReferenceProposition: FunctionComponent = () => {
  const { language } = useLanguage()
  const { contractId, product } = useParams()
  const { token } = useSelector<RootState, AuthState>(state => state.auth)
  const history = useHistory()

  const Layout = token ? LayoutBase : LayoutCenter

  const navigateBack = useCallback(
    e => {
      e.preventDefault()
      history.goBack()
    },
    [history]
  )

  return (
    <Layout>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.referenceProposition}`} />
        </Helmet>
      )}
      <Row gutter={[16, 24]} align="stretch">
        <Col xs={24} md={24}>
          <ReferencePropositionComponent baseUrl={window.brandConfig.baseUrl} token={token} contractId={contractId} product={product} language={language} />
        </Col>
        {token && (
          <Col xs={24} md={24}>
            <Button icon={<LeftOutlined />} onClick={navigateBack}>
              {translations[language].goBackTo} {translations[language].previousPage}
            </Button>
          </Col>
        )}
      </Row>
    </Layout>
  )
}

export default ReferenceProposition
