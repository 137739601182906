import { ResendConfirmationCodeForm } from 'ecedo-myportal-frontend-components'
import React, { FunctionComponent, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom'

import LayoutCenter from '../components/LayoutCenter'
import useLanguage from '../hooks/useLanguage'
import { getLocalizedRoutesForHistoryPush, localizedRoutes } from '../utils'

const ResendCodePage: FunctionComponent = () => {
  const history = useHistory()
  const { language } = useLanguage()

  const goToConfirm = useCallback(() => {
    history.push(getLocalizedRoutesForHistoryPush('confirmAccount'))
  }, [history])

  return (
    <LayoutCenter>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.resendCode}`} />
        </Helmet>
      )}
      <ResendConfirmationCodeForm language={language} navigateToNextScreen={goToConfirm} />
    </LayoutCenter>
  )
}

export default ResendCodePage
