import { Layout } from 'antd'
import { Cookie, Version } from 'ecedo-myportal-frontend-components'
import React, { FunctionComponent, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import useLanguage from '../hooks/useLanguage'
import { getLocalizedRoutesForHistoryPush } from '../utils'

interface LayoutCenterProps {
  top?: boolean
}

const FullLayout = styled(({ top, ...props }) => <Layout {...props} />)`
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: ${(props: { top: boolean }): string => (props.top ? 'flex-start' : 'center')};
  position: relative;
  padding: 16px 0;
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 24px;
  width: 150px;
  img {
    width: 100%;
    max-width: 150px;
    max-height: 80px;
    object-fit: contain;
  }
`

const VersionContainer = styled.div`
  position: absolute;
  bottom: 15px;
  right: 15px;
`

const LayoutCenter: FunctionComponent<LayoutCenterProps> = ({ top = false, children }) => {
  const { language } = useLanguage()
  const history = useHistory()

  const handleMoreInfo = useCallback(
    e => {
      e.preventDefault()
      history.push(getLocalizedRoutesForHistoryPush('privacyPolicy'))
    },
    [history]
  )
  return (
    <FullLayout top={top}>
      <LogoContainer>
        {window.brandConfig.loginLogoUrl ? (
          <a href={window.brandConfig.loginLogoUrl}>
            <img src={`${process.env.PUBLIC_URL}/brand/brand.logo.svg`} alt={window.brandConfig.name || 'Logo'} />
          </a>
        ) : (
          <img src={`${process.env.PUBLIC_URL}/brand/brand.logo.svg`} alt={window.brandConfig.name || 'Logo'} />
        )}
      </LogoContainer>
      {children}
      <VersionContainer>
        <Version baseUrl={window.brandConfig.baseUrl} language={language} />
      </VersionContainer>
      <Cookie
        handleMoreInfo={handleMoreInfo}
        googleTagManagerId={window.brandConfig.googleTagManagerId}
        googleAnalyticsId={window.brandConfig.googleAnalyticsId}
        cookieExpires={window.brandConfig.cookieExpires}
        language={language}
      />
    </FullLayout>
  )
}

export default LayoutCenter
