import { Button, Col, Row } from 'antd'
import { CookiePrivacyPolicy } from 'ecedo-myportal-frontend-components'
import React, { FunctionComponent, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import LayoutBase from '../components/LayoutBase'
import LayoutCenter from '../components/LayoutCenter'
import useLanguage from '../hooks/useLanguage'
import { RootState } from '../store/reducers'
import { AuthState } from '../store/reducers/authReducer'
import translations from '../translation'
import { localizedRoutes } from '../utils'

const PrivacyPolicyCol = styled(Col)`
  margin: 0 auto;
  padding-bottom: 180px !important;
`

const BackButton = styled(Button)`
  margin-top: 16px;
`

const PrivacyPolicy: FunctionComponent = () => {
  const { language } = useLanguage()
  const { token } = useSelector<RootState, AuthState>(state => state.auth)
  const history = useHistory()

  const Layout = token ? LayoutBase : LayoutCenter

  const navigateBack = useCallback(
    e => {
      e.preventDefault()
      history.goBack()
    },
    [history]
  )

  return (
    <Layout>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.privacyPolicy}`} />
        </Helmet>
      )}
      <Row gutter={[16, 24]} align="stretch">
        <PrivacyPolicyCol xs={24} lg={token ? 24 : 12}>
          <CookiePrivacyPolicy language={language} />
          <BackButton type="primary" onClick={navigateBack}>
            {translations[language].goBackTo} {translations[language].previousPage}
          </BackButton>
        </PrivacyPolicyCol>
      </Row>
    </Layout>
  )
}

export default PrivacyPolicy
