import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface MenuState {
  open: boolean
}

const initialState: MenuState = {
  open: false
}

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setMenu(state, action: PayloadAction<boolean>): void {
      state.open = action.payload
    },
    toggleMenu(state): void {
      state.open = !state.open
    }
  }
})

export const { toggleMenu, setMenu } = menuSlice.actions

export type MenuAction = ReturnType<typeof menuSlice.actions[keyof typeof menuSlice.actions]>

export default menuSlice.reducer
