import { Col, Row } from 'antd'
import { ContractAndTariff } from 'ecedo-myportal-frontend-components'
import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'

import LayoutBase from '../components/LayoutBase'
import useDaughterOrganization from '../hooks/useDaughterOrganization'
import useLanguage from '../hooks/useLanguage'
import { RootState } from '../store/reducers'
import { OrganizationState } from '../store/reducers/organizationReducer'
import { localizedRoutes } from '../utils'

interface DaughterContractsProps {
  token: string
}

const DaughterContracts: FunctionComponent<DaughterContractsProps> = ({ token }) => {
  const { language } = useLanguage()
  const { daughterOrganization, connectionGroup } = useSelector<RootState, OrganizationState>(state => state.organization)

  useDaughterOrganization()

  return (
    <LayoutBase>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.daughterContracts}`} />
        </Helmet>
      )}
      <Row gutter={[16, 24]} align="stretch">
        <Col xs={24} md={24}>
          <ContractAndTariff
            baseUrl={window.brandConfig.baseUrl}
            token={token}
            language={language}
            priceColumn={{ xs: 1, sm: 1, md: 2, lg: 3 }}
            organizationId={daughterOrganization?.organizationId || ''}
            connectionGroupId={connectionGroup.connectionGroupId || ''}
          />
        </Col>
      </Row>
    </LayoutBase>
  )
}

export default DaughterContracts
