import { Typography } from 'antd'
import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import LayoutCenter from '../components/LayoutCenter'
import { Empty } from '../components/SVG'
import useLanguage from '../hooks/useLanguage'
import translations from '../translation'
import { getLocalizedRoutesForHistoryPush } from '../utils'

interface Error404Props {
  token?: string
}

const SVGContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 300px;
  margin: 12px 0;
  svg {
    width: 100%;
    height: 100%;
    ellipse[fill='#6c63ff'][opacity='0.1'],
    path[fill='#6c63ff'][opacity='0.1'] {
      fill: var(--primary-color, #2263bf);
      opacity: 0.1;
    }
    polygon[fill='#6c63ff'],
    path[fill='#6c63ff'] {
      fill: var(--primary-color, #2263bf);
    }
  }
`

const NotFound: FunctionComponent<Error404Props> = ({ token }) => {
  const { language } = useLanguage()
  return (
    <LayoutCenter>
      <SVGContainer>
        <Empty />
      </SVGContainer>
      <Typography.Title level={3}>{translations[language].pageNotFound}</Typography.Title>
      <Typography.Paragraph>
        {translations[language].goBackTo}{' '}
        {token ? (
          <Link to="/">
            <a>{translations[language].dashboard}</a>
          </Link>
        ) : (
          <Link to={getLocalizedRoutesForHistoryPush('login')}>
            <a>{translations[language].login}</a>
          </Link>
        )}
      </Typography.Paragraph>
    </LayoutCenter>
  )
}

export default NotFound
