import { Col, Row } from 'antd'
import { FAQ, MeterReadingsReport } from 'ecedo-myportal-frontend-components'
import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import LayoutCenter from '../components/LayoutCenter'
import useLanguage from '../hooks/useLanguage'
import { localizedRoutes } from '../utils'

const MeterReadingsPublicContainer = styled.div`
  display: flex;
  width: 690px;
  > .ant-card {
    width: 100%;
  }
`

const MeterReadingsPublicFAQContainer = styled.div`
  display: flex;
  > .ant-card {
    width: 500px;
  }
`

const MeterReadingsPublic: FunctionComponent = () => {
  const { language } = useLanguage()
  const { organizationId, connectionGroupId } = useParams()

  return (
    <LayoutCenter top>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.meterReadings}`} />
        </Helmet>
      )}
      <Row gutter={[16, 24]}>
        <Col>
          <MeterReadingsPublicContainer>
            <MeterReadingsReport
              baseUrl={window.brandConfig.baseUrl}
              organizationId={organizationId}
              connectionGroupId={connectionGroupId}
              language={language}
              inline={!!connectionGroupId}
              unAuth
              multiple={!connectionGroupId}
              useEventDateForMeterReadingEntry={window.brandConfig.useEventDateForMeterReadingEntry}
            />
          </MeterReadingsPublicContainer>
        </Col>
        <Col>
          <MeterReadingsPublicFAQContainer>
            <FAQ baseUrl={window.brandConfig.baseUrl} token="" language={language} page="meterReadingsPublic" />
          </MeterReadingsPublicFAQContainer>
        </Col>
      </Row>
    </LayoutCenter>
  )
}

export default MeterReadingsPublic
