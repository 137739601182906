interface Breakpoints {
  xs: string
  sm: string
  md: string
  lg: string
  xl: string
  xxl: string
}

export const breakpoints: Breakpoints = {
  xs: '480px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px'
}
