import SignUpFlow from 'ecedo-myportal-frontend-components/components/SignUpFlow'
import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet-async'

import LayoutCenter from '../components/LayoutCenter'
import { localizedRoutes } from '../utils'

const SignUpPage: FunctionComponent = () => {
  return (
    <LayoutCenter>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.signUp}`} />
        </Helmet>
      )}
      <SignUpFlow baseUrl={window.brandConfig.baseSignUpUrl} />
    </LayoutCenter>
  )
}

export default SignUpPage
