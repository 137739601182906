const en = {
  general: 'General',
  connections: 'Connections',
  financial: 'Financial',
  myData: 'My Data',
  contracts: 'Contracts',
  myMeterReadings: 'My Meter Readings',
  meterReadings: 'Meter Readings',
  logout: 'Logout',
  faq: 'FAQ',
  myLocations: 'My Locations',
  consumption: 'Consumption',
  pageNotFound: 'Page not found!',
  goBackTo: 'Go back to',
  dashboard: 'the dashboard',
  login: 'the login page',
  admin: 'Admin Page',
  previousPage: 'Previous page',
  organizationRegistration: 'Organization Registration'
}

export type enType = typeof en

export default en
