import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { RootState } from '../store/reducers'
import { OrganizationState, setDaughterOrganization } from '../store/reducers/organizationReducer'
import { getLocalizedRoutesForHistoryPush } from '../utils'

const useDaughterOrganization = (): void => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { organizationReference } = useParams()
  const { daughterOrganization, daughterOrganizationList } = useSelector<RootState, OrganizationState>(state => state.organization)

  useEffect(() => {
    if (!daughterOrganization.organizationId && daughterOrganizationList.length > 0) {
      const actualPath = location.pathname
      dispatch(setDaughterOrganization(daughterOrganizationList.find(organization => organization.organizationReference === organizationReference)))
      if (!actualPath.includes(getLocalizedRoutesForHistoryPush('connections')) || actualPath.includes(getLocalizedRoutesForHistoryPush('general'))) {
        history.push(`${getLocalizedRoutesForHistoryPush('connections')}/${organizationReference}`)
      }
    }
  }, [daughterOrganization.organizationId, daughterOrganizationList])
}

export default useDaughterOrganization
