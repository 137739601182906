import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

import CardStretched from './CardStretched'

const FullHeightCard = styled(CardStretched)`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    max-height: 100px;
    object-fit: contain;
  }
`

const CardLogo: FunctionComponent = () => {
  return (
    <FullHeightCard>
      <img src={`${process.env.PUBLIC_URL}/brand/brand.logo.svg`} alt={window.brandConfig.name || 'Logo'} />
    </FullHeightCard>
  )
}

export default CardLogo
