import {
  DashboardOutlined,
  FileTextOutlined,
  LogoutOutlined,
  BookOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  BuildOutlined,
  AppstoreOutlined,
  HomeOutlined,
  BarChartOutlined,
  AppstoreAddOutlined
} from '@ant-design/icons'
import React from 'react'

import translations, { SupportedLanguages } from '../translation'
import { getLocalizedRoutesForHistoryPush } from '../utils'

interface MenuItem {
  icon: JSX.Element
  name: string
  link: string
  disabled: boolean
}

export const defaultMenuList = (language: SupportedLanguages): MenuItem[] => {
  const menuList = [
    {
      icon: <HomeOutlined />,
      name: translations[language].general,
      link: '/',
      disabled: false
    },
    {
      icon: <FileTextOutlined />,
      name: translations[language].financial,
      link: getLocalizedRoutesForHistoryPush('financial'),
      disabled: false
    },
    {
      icon: <UserOutlined />,
      name: translations[language].myData,
      link: getLocalizedRoutesForHistoryPush('myData'),
      disabled: false
    },
    {
      icon: <BookOutlined />,
      name: translations[language].contracts,
      link: getLocalizedRoutesForHistoryPush('contracts'),
      disabled: false
    },
    {
      icon: <DashboardOutlined />,
      name: translations[language].myMeterReadings,
      link: getLocalizedRoutesForHistoryPush('meterReadings'),
      disabled: false
    }
  ]

  if (window?.brandConfig?.showCharts) {
    menuList.push({
      icon: <BarChartOutlined />,
      name: translations[language].consumption,
      link: getLocalizedRoutesForHistoryPush('charts'),
      disabled: false
    })
  }

  if (window?.brandConfig?.showFAQ) {
    menuList.push({
      icon: <QuestionCircleOutlined />,
      name: translations[language].faq,
      link: getLocalizedRoutesForHistoryPush('faq'),
      disabled: false
    })
  }

  if (window?.brandConfig?.lambdaUrl) {
    menuList.push({
      icon: <AppstoreAddOutlined />,
      name: translations[language].organizationRegistration,
      link: getLocalizedRoutesForHistoryPush('organizationRegistration'),
      disabled: false
    })
  }

  menuList.push({
    icon: <LogoutOutlined />,
    name: translations[language].logout,
    link: '/logout',
    disabled: false
  })

  return menuList
}

export const parentMenuList = (language: SupportedLanguages): MenuItem[] => {
  const menuList = [
    {
      icon: <HomeOutlined />,
      name: translations[language].general,
      link: '/',
      disabled: false
    },
    {
      icon: <BuildOutlined />,
      name: translations[language].myLocations,
      link: getLocalizedRoutesForHistoryPush('myLocations'),
      disabled: false
    },
    {
      icon: <FileTextOutlined />,
      name: translations[language].financial,
      link: getLocalizedRoutesForHistoryPush('financial'),
      disabled: false
    },
    {
      icon: <UserOutlined />,
      name: translations[language].myData,
      link: getLocalizedRoutesForHistoryPush('myData'),
      disabled: false
    }
  ]

  if (window?.brandConfig?.showCharts) {
    menuList.push({
      icon: <BarChartOutlined />,
      name: translations[language].consumption,
      link: getLocalizedRoutesForHistoryPush('charts'),
      disabled: false
    })
  }

  if (window?.brandConfig?.showFAQ) {
    menuList.push({
      icon: <QuestionCircleOutlined />,
      name: translations[language].faq,
      link: getLocalizedRoutesForHistoryPush('faq'),
      disabled: false
    })
  }

  menuList.push({
    icon: <LogoutOutlined />,
    name: translations[language].logout,
    link: '/logout',
    disabled: false
  })

  return menuList
}

export const descendantMenuList = (language: SupportedLanguages, organizationId: string): MenuItem[] => {
  return [
    {
      icon: <HomeOutlined />,
      name: translations[language].general,
      link: `${getLocalizedRoutesForHistoryPush('general')}/${organizationId}`,
      disabled: false
    },
    {
      icon: <AppstoreOutlined />,
      name: translations[language].connections,
      link: `${getLocalizedRoutesForHistoryPush('connections')}/${organizationId}`,
      disabled: false
    }
  ]
}
