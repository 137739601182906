import { combineReducers } from '@reduxjs/toolkit'

import authReducer, { AuthAction } from './authReducer'
import languageReducer, { LanguageAction } from './languageReducer'
import menuReducer, { MenuAction } from './menuReducer'
import organizationReducer, { OrganizationAction } from './organizationReducer'

const rootReducer = combineReducers({ language: languageReducer, menu: menuReducer, auth: authReducer, organization: organizationReducer })

export type AppAction = LanguageAction & MenuAction & AuthAction & OrganizationAction

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
