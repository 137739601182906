import en, { enType } from './en'
import nl, { nlType } from './nl'

const brandEn = window.brandConfig?.translations?.global?.en || {}
const brandNl = window.brandConfig?.translations?.global?.nl || {}

const translations: {
  en: enType
  nl: nlType
} = {
  en: {
    ...en,
    ...brandEn
  },
  nl: {
    ...nl,
    ...brandNl
  }
}
export type gtType = typeof translations

export type SupportedLanguages = 'en' | 'nl'

export default translations
