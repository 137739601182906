export const localizedRoutes = {
  nl: {
    connections: '/verbindingen',
    contracts: '/contracten',
    charts: '/grafieken',
    confirmAccount: '/bevestigen',
    createAccount: '/registreren',
    daughterConnections: '/verbindingen/:organizationReference',
    daughterContracts: '/contracten/:organizationReference',
    daughterData: '/algemeen/:organizationReference',
    daughterFinancial: '/financieel/:organizationReference',
    daughterMeterReadings: '/meterstanden/:organizationReference',
    faq: '/faq',
    financial: '/financieel',
    financialDetails: '/financieel-details/:invoiceId',
    forgotPasswordFinish: '/wachtwoord-bevestigen',
    forgotPasswordStart: '/wachtwoord',
    general: '/algemeen',
    login: '/inloggen',
    meterReadings: '/meterstanden',
    meterReadingsPublic: '/meterstanden-invoeren/:organizationId/:connectionGroupId',
    meterReadingsPublicMultiple: '/meterstanden-invoeren/:organizationId',
    myLocations: '/mijn-locaties',
    myData: '/mijn-data',
    newPassword: '/nieuw-wachtwoord',
    privacyPolicy: '/privacybeleid',
    resendCode: '/code-nogmaals-versturen',
    referenceProposition: '/referentie-product/:contractId/:product',
    signUp: '/aanmelden',
    terminationFee: '/opzegvergoeding/:contractId/:product',
    organizationRegistration: '/organization-registration'
  },
  en: {
    charts: '/charts',
    connections: '/connections',
    contracts: '/contracts',
    confirmAccount: '/confirm-account',
    createAccount: '/create-account',
    daughterConnections: '/connections/:organizationReference',
    daughterContracts: '/contracts/:organizationReference',
    daughterData: '/general/:organizationReference',
    daughterFinancial: '/financial/:organizationReference',
    daughterMeterReadings: '/meter-readings/:organizationReference',
    general: '/general',
    faq: '/faq',
    financial: '/financial',
    financialDetails: '/financial-details/:invoiceId',
    forgotPasswordFinish: '/forgot-password-finish',
    forgotPasswordStart: '/forgot-password',
    login: '/login',
    meterReadings: '/meter-readings',
    meterReadingsPublic: '/enter-meter-readings/:organizationId/:connectionGroupId',
    meterReadingsPublicMultiple: '/enter-meter-readings/:organizationId',
    myLocations: '/my-locations',
    myData: '/my-data',
    newPassword: '/new-password',
    privacyPolicy: '/privacy-policy',
    resendCode: '/resend-code',
    referenceProposition: '/reference-proposition/:contractId/:product',
    signUp: '/sign-up',
    terminationFee: '/termination-fee/:contractId/:product',
    organizationRegistration: '/organization-registration'
  }
}

export type LocalizedRouteLanguageType = keyof typeof localizedRoutes
export type LocalizedRouteType = keyof typeof localizedRoutes[LocalizedRouteLanguageType]

export const getLocalizedRoutes = (type: LocalizedRouteType): string | string[] =>
  window.brandConfig.languageUrl === 'both' || !window.brandConfig.languageUrl
    ? [localizedRoutes.nl[type], localizedRoutes.en[type]]
    : localizedRoutes[(window.brandConfig.languageUrl || 'en') as LocalizedRouteLanguageType][type]

export const getLocalizedRoutesForHistoryPush = (type: LocalizedRouteType): string =>
  window.brandConfig.languageUrl === 'both' ? getLocalizedRoutes(type)[0] : (getLocalizedRoutes(type) as string)
