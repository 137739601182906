import { LoginForm } from 'ecedo-myportal-frontend-components'
import React, { FunctionComponent, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useHistory } from 'react-router-dom'

import LayoutCenter from '../components/LayoutCenter'
import useLanguage from '../hooks/useLanguage'
import { getLocalizedRoutesForHistoryPush, localizedRoutes } from '../utils'

const LoginPage: FunctionComponent = () => {
  const { language } = useLanguage()
  const history = useHistory()

  const navigateToNewPassword = useCallback(() => {
    history.push(getLocalizedRoutesForHistoryPush('newPassword'))
  }, [history])

  const navigateToHome = useCallback(() => {
    history.push('/')
  }, [history])

  const navigateToConfirm = useCallback(() => {
    history.push(getLocalizedRoutesForHistoryPush('confirmAccount'))
  }, [history])

  return (
    <LayoutCenter>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.login}`} />
        </Helmet>
      )}
      <LoginForm
        language={language}
        forgetLink={getLocalizedRoutesForHistoryPush('forgotPasswordStart')}
        navigateToHome={navigateToHome}
        navigateToNewPassword={navigateToNewPassword}
        createAccountLink={getLocalizedRoutesForHistoryPush('createAccount')}
        navitageToConfirm={navigateToConfirm}
        Link={Link}
      />
    </LayoutCenter>
  )
}

export default LoginPage
