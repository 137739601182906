import { Col, Row } from 'antd'
import { AdvancePayment, ChartMarketPrice, FAQ, InvoicesList, Welcome } from 'ecedo-myportal-frontend-components'
import React, { FunctionComponent, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import CardLogo from '../components/CardLogo'
import LayoutBase from '../components/LayoutBase'
import useLanguage from '../hooks/useLanguage'
import { RootState } from '../store/reducers'
import { OrganizationState } from '../store/reducers/organizationReducer'
import { getLocalizedRoutesForHistoryPush } from '../utils'

interface DashboardProps {
  token: string
}

const FlexCol = styled(Col)`
  display: flex;
  align-items: stretch;
  .ant-card {
    flex: 1;
  }
`

const Dashboard: FunctionComponent<DashboardProps> = ({ token }) => {
  const { language } = useLanguage()
  const { parent } = useSelector<RootState, OrganizationState>(state => state.organization)
  const history = useHistory()

  const handleSelectInvoice = useCallback(
    (invoiceId: string) => {
      history.push(getLocalizedRoutesForHistoryPush('financialDetails').replace(':invoiceId', invoiceId))
    },
    [history]
  )

  return (
    <LayoutBase>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}`} />
        </Helmet>
      )}
      <Row gutter={[16, 24]} align="stretch">
        <Col xs={24} lg={16}>
          <Welcome baseUrl={window.brandConfig.baseUrl} token={token} language={language} />
        </Col>
        <Col xs={24} lg={8}>
          <CardLogo />
        </Col>
        {!parent && (
          <FlexCol xs={24} lg={8}>
            <AdvancePayment baseUrl={window.brandConfig.baseUrl} token={token} language={language} breakpoints={{ xs: 24, sm: 24, md: 24, lg: 24 }} />
          </FlexCol>
        )}
        <FlexCol xs={24} lg={!parent ? 16 : 24}>
          <InvoicesList baseUrl={window.brandConfig.baseUrl} token={token} language={language} selectInvoice={handleSelectInvoice} lastInvoice />
        </FlexCol>
        <Col xs={24}>
          <ChartMarketPrice baseUrl={window.brandConfig.baseUrl} token={token} language={language} labels={window.brandConfig.chartLabels} />
        </Col>
        {window.brandConfig.showFAQOnDashboard && (
          <Col xs={24}>
            <FAQ baseUrl={window.brandConfig.baseUrl} token={token} language={language} />
          </Col>
        )}
      </Row>
    </LayoutBase>
  )
}

export default Dashboard
