import React, { FunctionComponent } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'

import useAuth from './hooks/useAuth'
import AccountCreation from './pages/AccountCreation'
import Charts from './pages/Charts'
import ConfirmAccount from './pages/ConfirmAccount'
import ContractList from './pages/ContractList'
import Dashboard from './pages/Dashboard'
import MyData from './pages/Data'
import DaughterConnections from './pages/DaughterConnections'
import DaughterContracts from './pages/DaughterContracts'
import DaughterData from './pages/DaughterData'
import DaughterFinancial from './pages/DaughterFinancial'
import DaughterMeterReadings from './pages/DaughterMeterReadings'
import FAQ from './pages/FAQ'
import ForgotFinish from './pages/ForgotPasswordFinish'
import ForgotStart from './pages/ForgotPasswordStart'
import InvoiceDetails from './pages/InvoiceDetails'
import InvoiceList from './pages/InvoiceList'
import Login from './pages/Login'
import MeterReadings from './pages/MeterReadings'
import MeterReadingsPublic from './pages/MeterReadingsPublic'
import MyLocationsPage from './pages/MyLocations'
import NewPassword from './pages/NewPassword'
import NotFound from './pages/NotFound'
import OrganizationRegistration from './pages/OrganizationRegistration'
import PrivacyPolicy from './pages/PrivacyPolicy'
import ReferencePropostion from './pages/ReferencePropostion'
import ResendCodePage from './pages/ResendCode'
import SignUpPage from './pages/SignUp'
import TerminationFee from './pages/TerminationFee'
import { getLocalizedRoutes } from './utils'

const Routes: FunctionComponent = () => {
  const { token } = useAuth()
  return (
    <Switch>
      <Route path={getLocalizedRoutes('daughterData')}>
        <DaughterData token={token} />
      </Route>
      <Route path={getLocalizedRoutes('daughterConnections')}>
        <DaughterConnections token={token} />
      </Route>
      <Route path={getLocalizedRoutes('daughterFinancial')}>
        <DaughterFinancial token={token} />
      </Route>
      <Route path={getLocalizedRoutes('daughterMeterReadings')}>
        <DaughterMeterReadings token={token} />
      </Route>
      <Route path={getLocalizedRoutes('daughterContracts')}>
        <DaughterContracts token={token} />
      </Route>
      <Route path={getLocalizedRoutes('meterReadings')} exact>
        <MeterReadings token={token} />
      </Route>
      <Route path={getLocalizedRoutes('meterReadingsPublic')} exact>
        <MeterReadingsPublic />
      </Route>
      <Route path={getLocalizedRoutes('meterReadingsPublicMultiple')} exact>
        <MeterReadingsPublic />
      </Route>
      {window?.brandConfig?.showCharts && (
        <Route path={getLocalizedRoutes('charts')}>
          <Charts token={token} />
        </Route>
      )}
      <Route path={getLocalizedRoutes('myLocations')}>
        <MyLocationsPage token={token} />
      </Route>
      <Route path={getLocalizedRoutes('financialDetails')}>
        <InvoiceDetails token={token} />
      </Route>
      <Route path={getLocalizedRoutes('financial')} exact>
        <InvoiceList token={token} />
      </Route>
      <Route path={getLocalizedRoutes('myData')}>
        <MyData token={token} />
      </Route>
      <Route path={getLocalizedRoutes('contracts')} exact>
        <ContractList token={token} />
      </Route>
      <Route path={getLocalizedRoutes('login')}>
        <Login />
      </Route>
      <Route path={getLocalizedRoutes('newPassword')}>
        <NewPassword />
      </Route>
      <Route path={getLocalizedRoutes('forgotPasswordStart')}>
        <ForgotStart />
      </Route>
      <Route path={getLocalizedRoutes('forgotPasswordFinish')}>
        <ForgotFinish />
      </Route>
      <Route path={getLocalizedRoutes('createAccount')}>
        <AccountCreation />
      </Route>
      <Route path={getLocalizedRoutes('confirmAccount')}>
        <ConfirmAccount />
      </Route>
      <Route path={getLocalizedRoutes('resendCode')}>
        <ResendCodePage />
      </Route>
      <Route path={getLocalizedRoutes('signUp')}>
        <SignUpPage />
      </Route>
      <Route path={getLocalizedRoutes('faq')}>
        <FAQ token={token} />
      </Route>
      <Route path={getLocalizedRoutes('terminationFee')}>
        <TerminationFee token={token} />
      </Route>
      <Route path={getLocalizedRoutes('referenceProposition')}>
        <ReferencePropostion />
      </Route>
      <Route path={getLocalizedRoutes('privacyPolicy')}>
        <PrivacyPolicy />
      </Route>
      <Route path={getLocalizedRoutes('organizationRegistration')}>
        <OrganizationRegistration token={token} />
      </Route>
      <Route path="/" exact>
        <Dashboard token={token} />
      </Route>
      <Route>
        <NotFound token={token} />
      </Route>
    </Switch>
  )
}

export default withRouter(Routes)
