import { Col, Row } from 'antd'
import { Chart } from 'ecedo-myportal-frontend-components'
import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

import LayoutBase from '../components/LayoutBase'
import useLanguage from '../hooks/useLanguage'
import { localizedRoutes } from '../utils'

interface InvoiceDetailsProps {
  token: string
}

const InvoiceDetails: FunctionComponent<InvoiceDetailsProps> = ({ token }) => {
  const { language } = useLanguage()
  const { invoiceId } = useParams()

  return (
    <LayoutBase>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.financial}`} />
        </Helmet>
      )}
      <Row gutter={[16, 24]} align="stretch">
        <Col xs={24}>
          <Chart baseUrl={window.brandConfig.baseUrl} token={token} language={language} invoiceId={invoiceId} labels={window.brandConfig.chartLabels} invoice />
        </Col>
      </Row>
    </LayoutBase>
  )
}

export default InvoiceDetails
