import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

interface Organization {
  organizationId?: string
  organizationName: string
  organizationReference: string
  clientReference?: string
  isParent?: boolean
}

interface ConnectionGroup {
  connectionGroupId: string
  connectionGroupAddress: string
  connectionGroupReference: string
}

export interface OrganizationState {
  parent: boolean
  selected: boolean
  breadcrumb: {
    active: number
    page?: string
  }
  organization: Organization | null
  pendingOrganization: boolean
  daughterOrganization: Organization
  daughterOrganizationList: Organization[]
  pendingDaughterOrganizationList: boolean
  connectionGroup: ConnectionGroup
}

const initialState: OrganizationState = {
  parent: false,
  selected: false,
  breadcrumb: {
    active: 1,
    page: ''
  },
  organization: null,
  pendingOrganization: true,
  daughterOrganization: { organizationId: '', organizationReference: '', organizationName: '' },
  daughterOrganizationList: [],
  pendingDaughterOrganizationList: true,
  connectionGroup: { connectionGroupId: '', connectionGroupAddress: '', connectionGroupReference: '' }
}

export const fetchOrganization = createAsyncThunk('organization/fetchOrganization', async (token: string) => {
  return await fetch(`${window.brandConfig.baseUrl}/api/organization`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((response: Response) => response.json())
})

export const fetchDaughterOrganizationList = createAsyncThunk('organization/fetchDaughterOrganizationList', async (token: string) => {
  return await fetch(`${window.brandConfig.baseUrl}/api/multisite/breadcrumb/daughters`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((response: Response) => response.json())
})

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setParent(state, action: PayloadAction<boolean>): void {
      state.parent = action.payload
    },
    setBreadcrumb(state, action: PayloadAction<{ active: number; page?: string }>): void {
      state.selected = action.payload.active > 1
      state.breadcrumb.active = action.payload.active
      state.breadcrumb.page = action.payload.page
    },
    setDaughterOrganization(state, action: PayloadAction<any>): void {
      state.daughterOrganization = action.payload
      state.breadcrumb.active = 2
      state.selected = true
    },
    setConnectionGroup(state, action: PayloadAction<any>): void {
      state.connectionGroup = action.payload.connectionGroup
      state.breadcrumb.active = 4
      state.breadcrumb.page = action.payload.page
      state.selected = true
    },
    resetOrganizationState(state): void {
      state.parent = initialState.parent
      state.selected = initialState.selected
      state.breadcrumb = initialState.breadcrumb
      state.organization = initialState.organization
      state.pendingOrganization = initialState.pendingOrganization
      state.daughterOrganization = initialState.daughterOrganization
      state.daughterOrganizationList = initialState.daughterOrganizationList
      state.pendingDaughterOrganizationList = initialState.pendingDaughterOrganizationList
      state.connectionGroup = initialState.connectionGroup
    }
  },
  extraReducers: {
    [fetchOrganization.pending.type](state): void {
      state.pendingOrganization = true
    },
    [fetchOrganization.fulfilled.type](state, action: PayloadAction<Organization>): void {
      state.parent = action.payload.isParent || false
      state.pendingOrganization = false
    },
    [fetchOrganization.rejected.type](state): void {
      state.parent = false
      state.pendingOrganization = false
    },
    [fetchDaughterOrganizationList.pending.type](state): void {
      state.pendingDaughterOrganizationList = true
    },
    [fetchDaughterOrganizationList.fulfilled.type](state, action: PayloadAction<{ daughterOrganizations: Organization[] }>): void {
      state.daughterOrganizationList = action.payload.daughterOrganizations || []
      state.pendingDaughterOrganizationList = false
    },
    [fetchDaughterOrganizationList.rejected.type](state): void {
      state.daughterOrganizationList = []
      state.pendingDaughterOrganizationList = false
    }
  }
})

export const { setParent, setBreadcrumb, setDaughterOrganization, setConnectionGroup, resetOrganizationState } = organizationSlice.actions

export type OrganizationAction = ReturnType<typeof organizationSlice.actions[keyof typeof organizationSlice.actions]>

export default organizationSlice.reducer
