import { Auth } from 'aws-amplify'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { cache } from 'swr'

import { RootState } from '../store/reducers'
import { AuthState, resetAuthState, setEmail, setGroups, setOrganizationId, setToken, setUserId } from '../store/reducers/authReducer'
import { fetchDaughterOrganizationList, fetchOrganization, OrganizationState, resetOrganizationState } from '../store/reducers/organizationReducer'
import { getLocalizedRoutesForHistoryPush, localizedRoutes } from '../utils'

const protectedRoutes: string[] = [
  '/',
  localizedRoutes.en.connections,
  localizedRoutes.nl.connections,
  localizedRoutes.en.contracts,
  localizedRoutes.nl.contracts,
  localizedRoutes.en.charts,
  localizedRoutes.nl.charts,
  localizedRoutes.en.faq,
  localizedRoutes.nl.faq,
  localizedRoutes.en.financial,
  localizedRoutes.nl.financial,
  localizedRoutes.en.financialDetails.substring(0, 18),
  localizedRoutes.nl.financialDetails.substring(0, 19),
  localizedRoutes.en.general,
  localizedRoutes.nl.general,
  localizedRoutes.en.meterReadings,
  localizedRoutes.nl.meterReadings,
  localizedRoutes.en.myLocations,
  localizedRoutes.nl.myLocations,
  localizedRoutes.en.myData,
  localizedRoutes.nl.myData,
  localizedRoutes.en.terminationFee.substring(0, 16),
  localizedRoutes.nl.terminationFee.substring(0, 16),
  localizedRoutes.en.organizationRegistration,
  localizedRoutes.nl.organizationRegistration
]

const loggedOutRoutes: string[] = [
  localizedRoutes.en.confirmAccount,
  localizedRoutes.nl.confirmAccount,
  localizedRoutes.en.createAccount,
  localizedRoutes.nl.createAccount,
  localizedRoutes.en.resendCode,
  localizedRoutes.nl.resendCode,
  localizedRoutes.en.forgotPasswordFinish,
  localizedRoutes.nl.forgotPasswordFinish,
  localizedRoutes.en.forgotPasswordStart,
  localizedRoutes.nl.forgotPasswordStart,
  localizedRoutes.en.meterReadingsPublic.substring(0, 21),
  localizedRoutes.nl.meterReadingsPublic.substring(0, 22),
  localizedRoutes.en.login,
  localizedRoutes.nl.login,
  localizedRoutes.en.newPassword,
  localizedRoutes.nl.newPassword,
  localizedRoutes.en.signUp,
  localizedRoutes.nl.signUp
]

const bothRoutes: string[] = [
  localizedRoutes.en.privacyPolicy,
  localizedRoutes.nl.privacyPolicy,
  localizedRoutes.en.referenceProposition.substring(0, 22),
  localizedRoutes.nl.referenceProposition.substring(0, 19)
]

interface AuthHookReturn {
  token: string
}

const useAuth = (): AuthHookReturn => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { token } = useSelector<RootState, AuthState>(state => state.auth)
  const { organization } = useSelector<RootState, OrganizationState>(state => state.organization)
  const actualPath = location.pathname
  let refreshTokenIntervalId: number | null = null

  useEffect(() => {
    const eventCallback = async (): Promise<void> => {
      await Auth.signOut()
      dispatch(resetAuthState())
      dispatch(resetOrganizationState())
      cache.clear()
      history.push(getLocalizedRoutesForHistoryPush('login'))
    }
    document.addEventListener('unauthorizedAPIError', eventCallback)
    return (): void => document.removeEventListener('unauthorizedAPIError', eventCallback)
  }, [dispatch, history])

  const handleLogin = useCallback(async () => {
    try {
      const data = await Auth.currentSession()
      const currentToken = data.getIdToken().getJwtToken()
      if (token !== currentToken) {
        dispatch(setToken(currentToken))
        dispatch(setEmail(data.getIdToken().payload.email))
        dispatch(setUserId(data.getIdToken().payload['cognito:username']))
        dispatch(setOrganizationId(data.getIdToken().payload['custom:OrganizationID']))
        dispatch(setGroups(data.getAccessToken().payload['cognito:groups'] ?? []))
        if (!organization?.organizationId) {
          dispatch(fetchOrganization(currentToken))
          dispatch(fetchDaughterOrganizationList(currentToken))
        }
        localStorage.clear()
        if (refreshTokenIntervalId) clearInterval(refreshTokenIntervalId)
        refreshTokenIntervalId = setInterval(handleLogin, 3000000)
      }
      if (loggedOutRoutes.includes(actualPath)) {
        history.push('/')
      }
    } catch (e) {
      if (
        protectedRoutes.some(item => actualPath.includes(item)) &&
        !loggedOutRoutes.some(item => actualPath.includes(item)) &&
        !bothRoutes.some(item => actualPath.includes(item))
      ) {
        history.push(getLocalizedRoutesForHistoryPush('login'))
      }
    }
  }, [dispatch, actualPath, history, token])

  useEffect(() => {
    handleLogin()
    return (): void => {
      if (refreshTokenIntervalId) clearInterval(refreshTokenIntervalId)
    }
  }, [handleLogin])

  return { token }
}
export default useAuth
