import { Col, Row } from 'antd'
import { InvoicesList, AdvancePayment } from 'ecedo-myportal-frontend-components'
import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'

import LayoutBase from '../components/LayoutBase'
import useDaughterOrganization from '../hooks/useDaughterOrganization'
import useLanguage from '../hooks/useLanguage'
import { RootState } from '../store/reducers'
import { OrganizationState } from '../store/reducers/organizationReducer'
import { localizedRoutes } from '../utils'

interface DaughterFinancialProps {
  token: string
}

const DaughterFinancial: FunctionComponent<DaughterFinancialProps> = ({ token }) => {
  const { language } = useLanguage()
  const { daughterOrganization, connectionGroup } = useSelector<RootState, OrganizationState>(state => state.organization)

  useDaughterOrganization()

  return (
    <LayoutBase>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.daughterFinancial}`} />
        </Helmet>
      )}
      <Row gutter={[16, 24]} align="stretch">
        <Col xs={24} md={12} lg={8} xl={6}>
          <AdvancePayment
            baseUrl={window.brandConfig.baseUrl}
            token={token}
            language={language}
            breakpoints={{ xs: 24, sm: 24, md: 24, lg: 24 }}
            organizationId={daughterOrganization?.organizationId || ''}
            connectionGroupId={connectionGroup.connectionGroupId || ''}
          />
        </Col>
        <Col xs={24}>
          <InvoicesList
            baseUrl={window.brandConfig.baseUrl}
            token={token}
            language={language}
            organizationId={daughterOrganization?.organizationId || ''}
            connectionGroupId={connectionGroup.connectionGroupId || ''}
          />
        </Col>
      </Row>
    </LayoutBase>
  )
}

export default DaughterFinancial
