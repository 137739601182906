const nl = {
  general: 'Algemeen',
  connections: 'Aansluitingen',
  financial: 'Financieel',
  myData: 'Mijn Gegevens',
  contracts: 'Contracten',
  myMeterReadings: 'Mijn Meterstanden',
  meterReadings: 'Meterstanden',
  logout: 'Uitloggen',
  faq: 'FAQ',
  myLocations: 'Mijn locaties',
  consumption: 'Verbruik',
  pageNotFound: 'Pagina niet gevonden.',
  goBackTo: 'Ga terug naar',
  dashboard: 'het dashboard',
  login: 'de inlogpagina',
  admin: 'Admin Pagina',
  previousPage: 'Vorige pagina',
  organizationRegistration: 'Organisatie Registratie'
}

export type nlType = typeof nl

export default nl
