import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'

import App from './App'
import './auth/services/amplify'
import store from './store'
import './styles/antd-light.less'

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
