import { Col, Row } from 'antd'
import { GeneralInfo, ContactInfo } from 'ecedo-myportal-frontend-components'
import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'

import LayoutBase from '../components/LayoutBase'
import useDaughterOrganization from '../hooks/useDaughterOrganization'
import useLanguage from '../hooks/useLanguage'
import { RootState } from '../store/reducers'
import { OrganizationState } from '../store/reducers/organizationReducer'
import { localizedRoutes } from '../utils'

interface DaughterDataProps {
  token: string
}

const DaughterData: FunctionComponent<DaughterDataProps> = ({ token }) => {
  const { language } = useLanguage()
  const { daughterOrganization } = useSelector<RootState, OrganizationState>(state => state.organization)

  useDaughterOrganization()

  return (
    <LayoutBase>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.daughterData}`} />
        </Helmet>
      )}
      <Row gutter={[16, 24]} align="stretch">
        <Col xs={24} md={12}>
          <ContactInfo
            baseUrl={window.brandConfig.baseUrl}
            token={token}
            language={language}
            editable={false}
            organizationId={daughterOrganization?.organizationId || ''}
          />
        </Col>
        <Col xs={24} md={12} lg={12} xl={8}>
          <GeneralInfo baseUrl={window.brandConfig.baseUrl} token={token} language={language} organizationId={daughterOrganization?.organizationId || ''} />
        </Col>
      </Row>
    </LayoutBase>
  )
}

export default DaughterData
