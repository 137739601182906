import { MenuUnfoldOutlined, MenuFoldOutlined, SettingOutlined } from '@ant-design/icons'
import { Divider, Grid, Layout, Menu, Spin } from 'antd'
import { Auth } from 'aws-amplify'
import camelcase from 'camelcase'
import { OrganizationBreadcrumb, Cookie, OrganizationSelect, OrganizationCard } from 'ecedo-myportal-frontend-components'
import React, { FunctionComponent, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { cache } from 'swr'

import { breakpoints } from '../config/responsive'
import useLanguage from '../hooks/useLanguage'
import { RootState } from '../store/reducers'
import { AuthState, resetAuthState, setOrganizationId, setToken } from '../store/reducers/authReducer'
import { MenuState, toggleMenu, setMenu } from '../store/reducers/menuReducer'
import { OrganizationState, resetOrganizationState, setBreadcrumb, setDaughterOrganization } from '../store/reducers/organizationReducer'
import translations from '../translation'
import { getLocalizedRoutesForHistoryPush } from '../utils'
import { defaultMenuList, descendantMenuList, parentMenuList } from './MenuList'

const { Header, Sider, Content } = Layout
const { useBreakpoint } = Grid

const FullLayout = styled(Layout)`
  min-height: 100vh;
  flex-direction: row;
`

const ContentHeader = styled(Header)`
  display: flex;
  align-items: center;
  background-color: transparent;
  margin: 0 0 6px;
  padding: 12px 18px;
  height: auto;
  line-height: initial;
  @media (min-width: ${breakpoints.md}) {
    padding: 0 24px;
    height: 64px;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding: 0 48px;
  }
  > div,
  > ul {
    margin-left: auto;
  }
`

const MainContent = styled(Content)`
  padding: 0 18px 9px;
  @media (min-width: ${breakpoints.md}) {
    padding: 0 24px 12px;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding: 0 48px 24px;
  }
`

const LogoContainer = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    max-width: 150px;
    max-height: 50px;
    object-fit: contain;
  }
`

const StyledSider = styled(Sider)`
  .ant-layout-sider-children {
    position: relative;
    .ant-menu-inline {
      border: 0;
    }
  }
`

const DescendantContainer = styled.div`
  background-color: white;
  border-top: 3px solid #f0f2f5;
  border-bottom: 3px solid #f0f2f5;
  margin: 6px 0;
`

const LayoutBase: FunctionComponent = ({ children }) => {
  const { language } = useLanguage()
  const { token, groups, userId, organizationId } = useSelector<RootState, AuthState>(state => state.auth)
  const history = useHistory()
  const dispatch = useDispatch()
  const { open } = useSelector<RootState, MenuState>(state => state.menu)
  const { parent, selected, pendingOrganization, pendingDaughterOrganizationList, daughterOrganization, breadcrumb, connectionGroup } = useSelector<
    RootState,
    OrganizationState
  >(state => state.organization)
  const { md } = useBreakpoint()

  const handleMenuToggle = useCallback(() => {
    dispatch(toggleMenu())
  }, [dispatch])

  const handleMenuOnBreakpoint = useCallback(
    (broken: boolean): void => {
      !open && dispatch(setMenu(broken))
    },
    [dispatch]
  )

  const handleMenuClick = useCallback(
    async item => {
      if (item.key === '/logout') {
        try {
          await Auth.signOut()
          dispatch(resetAuthState())
          dispatch(resetOrganizationState())
          cache.clear()
          window.location.href = window.brandConfig.logoutRedirectUrl ? window.brandConfig.logoutRedirectUrl : getLocalizedRoutesForHistoryPush('login')
        } catch (e) {
          console.error(e)
        }
      } else {
        history.push(item.key)
      }
    },
    [history]
  )

  const handleAdminMenuClick = useCallback(item => {
    window.location.href = item.key
  }, [])

  const handleDescendantMenuClick = useCallback(
    item => {
      history.push(item.key)
      const active = item.key.includes('general') ? 2 : 3
      dispatch(setBreadcrumb({ active }))
    },
    [history, dispatch]
  )

  const handleClose = useCallback(() => {
    history.push('/')
    dispatch(setBreadcrumb({ active: 1 }))
  }, [dispatch, history])

  const mainMenuList = parent ? parentMenuList(language) : defaultMenuList(language)

  const handleDaughterSelect = useCallback(
    (organization: any) => {
      history.push(`${getLocalizedRoutesForHistoryPush('general')}/${organization?.organizationReference}`)
      dispatch(setDaughterOrganization(organization))
    },
    [dispatch]
  )

  const handleConnections = useCallback(() => {
    history.push(`${getLocalizedRoutesForHistoryPush('connections')}/${daughterOrganization?.organizationReference}`)
    dispatch(setBreadcrumb({ active: 3 }))
  }, [dispatch])

  const handleMoreInfo = useCallback(
    e => {
      e.preventDefault()
      history.push(getLocalizedRoutesForHistoryPush('privacyPolicy'))
    },
    [history]
  )

  const handleChangeOrganization = useCallback(
    ({ token, organizationId }: { token: string; organizationId: string }): void => {
      dispatch(setToken(token))
      dispatch(setOrganizationId(organizationId))
      cache.clear()
      window.location.reload()
    },
    [dispatch]
  )

  return (
    <FullLayout>
      <StyledSider trigger={null} collapsible collapsed={open} collapsedWidth={md ? 80 : 0} breakpoint="lg" onBreakpoint={handleMenuOnBreakpoint}>
        <LogoContainer>
          <img src={`${process.env.PUBLIC_URL}/brand/brand.logo.svg`} alt={window.brandConfig.name || 'Logo'} />
        </LogoContainer>
        {!selected ? (
          <>
            <OrganizationSelect
              baseUrl={window.brandConfig.baseUrl}
              token={token}
              lambdaUrl={window.brandConfig.lambdaUrl}
              userId={userId}
              selectedOrganizationId={organizationId}
              handleChangeOrganization={handleChangeOrganization}
            />
            <Spin spinning={pendingOrganization || pendingDaughterOrganizationList}>
              <Menu mode="inline" selectedKeys={[location.pathname]} onClick={handleMenuClick}>
                {mainMenuList.map(item => (
                  <Menu.Item key={item.link} disabled={item.disabled}>
                    {item.icon}
                    <span>{item.name}</span>
                  </Menu.Item>
                ))}
              </Menu>
            </Spin>
          </>
        ) : (
          <DescendantContainer>
            <OrganizationCard
              number={daughterOrganization?.organizationReference || ''}
              name={daughterOrganization?.organizationName || ''}
              show={!!daughterOrganization}
              icon
              handleClose={handleClose}
            />
            <Menu mode="inline" selectedKeys={[location.pathname]} onClick={handleDescendantMenuClick}>
              {descendantMenuList(language, daughterOrganization?.organizationReference || '').map(item => (
                <Menu.Item key={item.link} disabled={item.disabled}>
                  {item.icon}
                  <span>{item.name}</span>
                </Menu.Item>
              ))}
            </Menu>
          </DescendantContainer>
        )}
        {groups.includes('admin') && (
          <>
            <Divider />
            <Menu mode="inline" selectedKeys={[location.pathname]} onClick={handleAdminMenuClick}>
              <Menu.Item key="/admin">
                <SettingOutlined />
                <span>{translations[language].admin}</span>
              </Menu.Item>
            </Menu>
          </>
        )}
      </StyledSider>
      <Layout>
        <ContentHeader>
          {React.createElement(open ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: handleMenuToggle
          })}
          <OrganizationBreadcrumb
            baseUrl={window.brandConfig.baseUrl}
            token={token}
            language={language}
            active={breadcrumb.active}
            page={translations[language as keyof typeof translations][camelcase(breadcrumb.page || '') as keyof typeof translations.en]}
            daughterOrganization={daughterOrganization as any}
            connectionGroup={connectionGroup as any}
            parentCallback={handleClose}
            daughterCallback={handleDaughterSelect}
            connectionCallback={handleConnections}
          />
        </ContentHeader>
        <MainContent>{children}</MainContent>
      </Layout>
      <Cookie
        handleMoreInfo={handleMoreInfo}
        googleTagManagerId={window.brandConfig.googleTagManagerId}
        googleAnalyticsId={window.brandConfig.googleAnalyticsId}
        cookieExpires={window.brandConfig.cookieExpires}
        language={language}
      />
    </FullLayout>
  )
}

export default LayoutBase
